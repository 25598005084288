


































































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { WechatImageTextService } from '@/services/wechat-imagetext-service';
import addClassify from '@/views/wechatImageText/classify/addClassify.vue';

@Component({
  components: {
    addClassify
  }
})
export default class imageTextClassify extends Vue {
  public queryParams: any = { //查询参数
    title: '',
    size: 10,
    current: 1,
    total: 0
  };
  public title: String = '创建图文分类';
  public dialogFormVisible: Boolean = false;
  public type: String = '0';
  public rowList: any = {}

  private tableData: any[] = [];
  public applications: any[] = [];

  private currentRow: any = null;

  @Inject(WechatImageTextService) private wechatImageTextService!: WechatImageTextService;

  public async created(): Promise<void> {
    await this.applicationsList()
    await this.getList()
  }
  public search() {
    this.queryParams.current = 1;
    this.getList()
  }
  //分页相关
  public handleSizeChange(size: number) {
    this.queryParams.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.queryParams.current = current;
    this.getList();
  }
  //获取数据源
  public async getList() {
    const res = await this.wechatImageTextService.articleTypeQuery(this.queryParams);
    let data: any = res.records
    this.formData(data)
    this.queryParams.total = res.total;
    this.queryParams.pages = res.pages;
    this.queryParams.size = res.size;
    this.queryParams.current = res.current;
  }
  //处理专栏id对应的name
  public async formData(res: any) {
    let data = res
    for (var item = 0; item < data.length; item++) {
      for (var i = 0; i < this.applications.length; i++) {
        if (this.applications[i].channelAgentId == data[item].agentId) {
          data[item].agentName = this.applications[i].name
        }
      }
    }
    this.tableData = data
  }
  //获取专栏
  public async applicationsList(): Promise<void> {
    const res = await this.wechatImageTextService.applicationsList();
    this.applications = res
  }
  //得到子组件的值
  public selectChange(e: any): void {
    this.dialogFormVisible = e
    this.search()
  }
  //新增
  public handAdd(): void {
    this.dialogFormVisible = true;
    this.title = '创建图文分类'
    this.type = '0'
  }
  //修改
  public handUpdate(row: any): void {
    this.dialogFormVisible = true;
    this.title = '修改图文分类'
    this.rowList = row
    this.type = '1'
  }
  //删除图文分类
  private async handDelete(row: any): Promise<void> {
    try {
      await this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      await this.wechatImageTextService.articleTypeDelete(row.id);
      this.search()
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    }
  }
  //返回
  public handleGoBack(row: any): void {
    this.$router.go(-1)
  }
  // /复制
  public copyUrl(url: any) {
    console.log(url)
    var oInput = document.createElement('input');
    oInput.value = url;
    document.body.appendChild(oInput);
    oInput.select();
    document.execCommand("Copy");
    this.$message({
      type: 'success',
      message: '复制成功'
    });
    oInput.remove();
  }
  // 重置
  public reset() {
    this.queryParams = { //查询参数
      title: '',
      size: 10,
      current: 1,
      total: 0
    };
    this.getList()
  }
  private mounted() {
    document.onkeydown = (e) => {
      let key = (window.event as any).keyCode;
      if (key == 13) {
        this.search()
      }
    }
  }

}
