












































































































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { WechatImageTextService } from '@/services/wechat-imagetext-service';
import wechatImage from '@/components/wechatImageText/wechatImageText.vue';

@Component({
  components: {
    wechatImage
  }
})
export default class addClassify extends Vue {
  @Prop({ default: true }) private title!: String;
  @Prop({ default: true }) private type!: String;
  @Prop({ default: true }) private dialogFormVisible!: Boolean;
  @Prop({ default: true }) private rowList!: any;


  public labelPosition: String = 'top';
  public typeId: String = '';
  public form: any = {
    agentId:'',
  };
  public rules: any = {
         agentId: [
            { required: true, message: '请选择专栏', trigger: ['change','blur'] }],
          title: [
            { required: true, message: '请输入分类名称', trigger: 'blur' }
          ],
          opt: [
            { required: true, message: '请输入分类描述', trigger: 'blur' }
          ]
  }
  public queryParams: any = { //查询参数
    pages: 1,
    size: 5,
    current: 1,
    total: 0
  };
  private tableData: any[] = [];
  private applications: any[] = [];

  @Inject(WechatImageTextService) private wechatImageTextService!: WechatImageTextService;

  public async created(): Promise<void> {
    await this.applicationsList()
    if(this.type == '1'){ //代表修改
      this.form = this.rowList
      this.handleChange(this.form.agentId)
      this.form.agentId = this.form.agentId.toString()
      this.typeId = this.rowList.id
      this.getList();
    }
  }
  //分页相关
  public handleSizeChange(size: number) {
    this.queryParams.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.queryParams.current = current;
    this.getList();
  }
  //获取数据源
  public async getList() : Promise<void>{
    const res = await this.wechatImageTextService.getArticleType(this.typeId,this.queryParams);
    this.tableData = res.records
    this.queryParams.total = res.total;
    this.queryParams.pages = res.pages;
    this.queryParams.size = res.size;
    this.queryParams.current = res.current;
  }
  //专栏改变得到选中行
  public handleChange(val: any){
    let checkId = this.applications.find( item => item.channelAgentId ==val).id
    this.form.channelId = checkId
  }
  //获取专栏
  public async applicationsList(): Promise<void>{
    const res = await this.wechatImageTextService.applicationsList();
    this.applications = res
    if(this.type!='1' && this.applications.length>0){//如果是添加的话，默认选中一个专栏
      this.form.agentId = this.applications[0].channelAgentId;
      this.$set(this.form, 'agentId', this.form.agentId.toString())
      this.handleChange(this.form.agentId)
    }
  }

  //删除图文
  private async handDelete(row: any): Promise<void> {
    let param ={
      typeId:0,
      id:row.id
    }
    try {
      await this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      await this.wechatImageTextService.articleUpdateOne(param);
      this.getList()
      this.$message({
        message: '删除成功',
        type: 'success'
      });
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    }
  }

  //保存修改的图文排序等
  public async handUpdate(row:any): Promise<void>{
    let res: any = await this.wechatImageTextService.articleUpdate(row);
    this.getList()
    this.$message({
        message: '保存成功',
        type: 'success'
    });
  }
  //保存
  public submitForm(formName:any) : void{
      const addRef = (this.$refs.form as any)
      addRef.validate( async (valid: any) => {
          if (valid) {
            if(this.type != '1'){
              let res: any = await this.wechatImageTextService.articleTypeAdd(this.form);
              this.$message({
                message: '添加成功',
                type: 'success'
              });
              this.cancel()
            }else{
              let newArr = (this.tableData.map((element) => {
                return {
                  ...element,
                  articleType: element.articleType || 0,
                }
              }))
              let newArr2 = (this.tableData.map((element) => {
                delete element.articleType
                return element
              }))
              console.log(newArr2,'newArr2')
              console.log(newArr,'newArr')
              let res: any = await this.wechatImageTextService.articleTypeUpdate(this.form);
              let data: any = await this.wechatImageTextService.articleUpdate(newArr2);
              let data2: any = await this.wechatImageTextService.updatesArticleNum(newArr);
              this.$message({
                message: '修改成功',
                type: 'success'
              });
              this.cancel()
            }
          } else {
            console.log('error submit!!');
            return false;
          }
      });
  }
  //返回
  @Emit('selectChange')
  public cancel(): Boolean {
    return false
  }
  public checkimage() {
   this.dialogFormVisible = true;
  }
}
