












































































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { WechatImageTextService } from '@/services/wechat-imagetext-service';

@Component({
  components: {
      
  }
})
export default class wechatImageText extends Vue {
  @Prop({ default: true }) private dialogFormVisible!: boolean; 
  public form: any = {
    pages: 1, 
    size: 5,
    current: 1
  };
  private tableData: any[] = [];
  private applications: any[] = [];
  private currentRow: any = '';
  public loading: boolean = true;
  @Inject(WechatImageTextService) private wechatImageTextService!: WechatImageTextService;

  public async created(): Promise<void> {
    var acceptChannelId = localStorage.getItem('acceptChannelId')
    if(acceptChannelId){
      this.form.channelId = Number(acceptChannelId);
    }
    await this.applicationsList()
    await this.getList()
  }
   private mounted() {
  }
  public search(){
    this.form.current = 1;
    if (this.form.createTime && this.form.createTime.length > 0) {
        this.form.startTime = this.form.createTime[0];
        this.form.endTime = this.form.createTime[1];
      } else {
        this.form.startTime = "";
        this.form.endTime = "";
      }
    this.getList()
  }
  //分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }
  //获取数据源
  public async getList() {
    const res = await this.wechatImageTextService.articleQuery(this.form);
    let data:any = res.records  
    this.formData(data)
    this.form.total = res.total;
    this.form.pages = res.pages;
    this.form.size = res.size;
    this.form.current = res.current; 
  }
  //处理专栏id对应的name
  public async formData(res:any){    
			let data = res     
			// data.map(item => {	
        for(var item=0;item< data.length;item++){
          for(var i=0;i<this.applications.length;i++){
              if(this.applications[i].channelAgentId == data[item].agentId){
                data[item].agentName= this.applications[i].name           
              }          
          }
        }	
			// })			
			this.tableData = data;
      this.loading =false; 			   
  }
  //获取专栏
  public async applicationsList(): Promise<void>{
    const res = await this.wechatImageTextService.applicationsList(); 
    this.applications = res   
  }  
  public handleCurrent(val:any): Promise<any> {
    this.currentRow = val;  
    this.getChild()  
    return this.currentRow
  }
  @Emit('selectChange')
  public cancal(): Boolean {
    let isDone: boolean = false;
    return isDone
  }
  @Emit('getChild')
  public getChild(): Boolean {   
    return this.currentRow
  }
   
}
